class API {
    static ROOT_DOMAIN_DEV = "http://localhost:3000";
    static ROOT_DOMAIN_DEV_DOCKER = "http://localhost";
    static STRIPE_URL_PAYMENT_LINK =
        "https://buy.stripe.com/test_9AQ8zaav6e5K6HKcMP";
    static ROOT_DOMAIN_PROD = "https://mydatamazing.com";
    static ROOT_DOMAIN = API.ROOT_DOMAIN_DEV;
    static BASE_URL = "";
    static BASE_URL_API = "/api/v1";

    static async getDirectLineToken(formValues) {
        try {
            const response = await fetch(`${API.BASE_URL}/chat-token`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formValues),
            });

            if (response.ok) {
                // Registration successful, handle accordingly
                return { success: true, data: await response.json(), status: response.status };
            } else {
                // Registration failed, handle accordingly
                return { success: false, status: response.status };
            }
        } catch (error) {
            console.error("Error:", error);
            return { success: false, error };
        }
    }

    static async logout() {
        try {
            const response = await fetch(`https://mydatamazing.com/v1/user/logout`, {
                method: "GET",
                credentials: "include",
                // body: JSON.stringify(formValues)
            });

            if (response.ok) {
                // Login successful, handle accordingly
                return { success: true };
            } else {
                // Login failed, handle accordingly
                return { success: false };
            }
        } catch (error) {
            console.error("Error:", error);
            return { success: false, error };
        }
    }
}

export default API;
